import { gql } from 'apollo-angular';

export interface GraphQlResponseVerifyCustomerPortalPassword {
  saveOwnerDocuments: string;
}

export const VERIFY_CUSTOMER_PORTAL_PASSWORD = gql`
  mutation VerifyCustomerPortalPassword($accessId: String!, $password: String!) {
    verifyCustomerPortalPassword(accessId: $accessId, password: $password)
  }
`;
