import { Injectable } from '@angular/core';
import { PropertyOverviewAgent, Theme, ThemeMetadata } from '@frontend/models';
import { BehaviorSubject, map } from 'rxjs';
import {TranslationService} from "../i18n/TranslationService";
import {CountrySettings} from "../types";
import {Language} from "../i18n/config";

const REMAX_THEME_METADATA: ThemeMetadata = {
  logoUrl: '/assets/themes/remax/logo.png',
};
const PROPUP_THEME_METADATA: ThemeMetadata = {
  logoUrl: '/assets/themes/propup/logo.png',
};

const SULEK_THEME_METADATA: ThemeMetadata = {
  logoUrl: '/assets/themes/sulek/sulek.png',
}

const WOHNCLOUD_THEME_METADATA: ThemeMetadata = {
  logoUrl: '/assets/themes/wohncloud/wohncloud.png',
}

const FROESCHL_THEME_METADATA: ThemeMetadata = {
  logoUrl: '/assets/themes/froeschl/froeschl.png',
}

const IMMOBILIEN_MELK_THEME_METADATA: ThemeMetadata = {
  logoUrl: '/assets/themes/immobilien-melk/immobilien-melk.png',
}

const GRASL_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/grasl/grasl-logo.png',
}

const FINOVA_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/finova/finova.png',
}

const HAGSTEINER_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/hagsteiner/hagsteiner.png',
}

const REMAX_COLLECTION_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/remax-collection/remaxCollection.png',
}

const REMAX_CLASSIC_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/remax-classic/logo.png',
}

const EDELWEISS_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/edelweiss/edelweiss.png',
}

const ERAIMMO_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/eraImmo/eraImmo.png',
}

const SONNBERGER_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/sonnberger/sonnberger.png',
}

const VIRIMO_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/virimo/virimo.png',
}

const NORISK_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/norisk/norisk.png',
}

const MAYRIMMO_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/mayrImmo/mayrimmo.png'
}

const PROSCH_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/prosch/prosch.png',
}

const AHIMMOBILIEN_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/ahImmobilien/ahImmobilien.png',
}

const AAAAIMMOBILIEN_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/aaaaImmobilien/aaaaImmobilien.png',
}

const IMMO4_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/4immobilien/4immobilien.png'
}

const ALTERA_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/altera/altera.png'
}

const QUALIS_THEME_METADATA: ThemeMetadata = {
  logoUrl: 'assets/themes/qualis/qualis.png'
}

const DEFAULT_THEME = Theme.Propup;
const DEFAULT_THEME_METADATA = PROPUP_THEME_METADATA;

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private _currentThemeName$$ = new BehaviorSubject<Theme>(DEFAULT_THEME);
  private _currentThemeMetadata$$ = new BehaviorSubject<ThemeMetadata>(DEFAULT_THEME_METADATA);

  themes = Object.values(Theme);

  currentThemeName$ = this._currentThemeName$$.asObservable();
  currentThemeMetadata$ = this._currentThemeMetadata$$.asObservable();

  isSulkeTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Sulek));
  isRemaxTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Remax));
  isPropupTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Propup));
  isWohnCloudTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.WohnCloud));
  isFroeschlTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Froeschl));
  isGraslTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Grasl));
  isImmo4Theme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Immo4));
  isImmobilienMelkTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.ImmobilienMelk));
  isAlteraTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Altera));
  isFinovaTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Finova));
  isHagsteinerTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Hagsteiner));
  isQualisTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Qualis));
  isEdelweissTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Edelweiss));
  isRemaxCollectionTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.RemaxCollection));
  isRemaxClassicTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.RemaxClassic));
  isProschTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Prosch));
  isAhImmobilienTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.AhImmobilien));
  isAaaaImmobilienTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.AaaaImmobilien));
  isNoriskTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Norisk));
  isMayrImmoTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.MayrImmo));
  isEraImmoTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.EraImmo));
  isSonnbergerTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Sonnberger));
  isVirimoTheme$ = this.currentThemeName$.pipe(map((theme) => theme === Theme.Virimo));

  currentThemeLogoUrl$ = this.currentThemeMetadata$.pipe(map(({ logoUrl }) => logoUrl));

  constructor(
      private readonly _translationService: TranslationService,
  ) {

  }

  setTheme(theme: Theme) {
    this._currentThemeName$$.next(theme);

    switch (theme) {
      case Theme.ImmobilienMelk:
        this._currentThemeMetadata$$.next(IMMOBILIEN_MELK_THEME_METADATA);
        break;
      case Theme.Immo4:
        this._currentThemeMetadata$$.next(IMMO4_THEME_METADATA);
        break;
      case Theme.WohnCloud:
        this._currentThemeMetadata$$.next(WOHNCLOUD_THEME_METADATA);
        break;
      case Theme.Froeschl:
        this._currentThemeMetadata$$.next(FROESCHL_THEME_METADATA);
        break;
      case Theme.Grasl:
        this._currentThemeMetadata$$.next(GRASL_THEME_METADATA);
        break;

      case Theme.Finova:
        this._currentThemeMetadata$$.next(FINOVA_THEME_METADATA);
        break;

      case Theme.Hagsteiner:
        this._currentThemeMetadata$$.next(HAGSTEINER_THEME_METADATA);
        break;

      case Theme.RemaxCollection:
        this._currentThemeMetadata$$.next(REMAX_COLLECTION_THEME_METADATA);
        break;

      case Theme.RemaxClassic:
        this._currentThemeMetadata$$.next(REMAX_CLASSIC_THEME_METADATA);
        break;

      case Theme.Edelweiss:
        this._currentThemeMetadata$$.next(EDELWEISS_THEME_METADATA);
        break;

      case Theme.EraImmo:
        this._currentThemeMetadata$$.next(ERAIMMO_THEME_METADATA);
        break;

      case Theme.Sonnberger:
        this._currentThemeMetadata$$.next(SONNBERGER_THEME_METADATA);
        break;

      case Theme.Virimo:
        this._currentThemeMetadata$$.next(VIRIMO_THEME_METADATA);
        break;

      case Theme.Norisk:
        this._currentThemeMetadata$$.next(NORISK_THEME_METADATA);
        break;

      case Theme.MayrImmo:
        this._currentThemeMetadata$$.next(MAYRIMMO_THEME_METADATA);
        break;

      case Theme.Prosch:
        this._currentThemeMetadata$$.next(PROSCH_THEME_METADATA);
        break;

      case Theme.AhImmobilien:
        this._currentThemeMetadata$$.next(AHIMMOBILIEN_THEME_METADATA);
        break;

      case Theme.AaaaImmobilien:
        this._currentThemeMetadata$$.next(AAAAIMMOBILIEN_THEME_METADATA);
        break;

      case Theme.Altera:
        this._currentThemeMetadata$$.next(ALTERA_THEME_METADATA);
        break;

      case Theme.Qualis:
        this._currentThemeMetadata$$.next(QUALIS_THEME_METADATA);
        break;

      case Theme.Sulek:
        this._currentThemeMetadata$$.next(SULEK_THEME_METADATA);
        break;

      case Theme.Remax:
        this._currentThemeMetadata$$.next(REMAX_THEME_METADATA);
        break;

      case Theme.Propup:
      default:
        this._currentThemeMetadata$$.next(PROPUP_THEME_METADATA);
    }
  }

  setCustomerPortalLanguage(agent?: PropertyOverviewAgent, customerLanguage?: string | null) {

    if (customerLanguage) {
      if((customerLanguage === "DE" || customerLanguage === "AT") && (agent?.countrySettings === "DE" || agent?.countrySettings === "AT")) {
        this._translationService.applyCountrySettingsLanguage(agent?.countrySettings as CountrySettings);
        return;
      }

      this._translationService.applyCountrySettingsLanguage(customerLanguage as CountrySettings);
      return;
    }

    this._translationService.applyCountrySettingsLanguage(agent?.countrySettings as CountrySettings);
  }

  setAgentRelatedTheme(agent?: PropertyOverviewAgent | null) {

    if (!agent) {
      this.setTheme(Theme.Propup);
      return;
    }

/*    this._translationService.applyCountrySettingsLanguage(agent?.countrySettings as CountrySettings)*/

    if (agent?.isRemax) {
      this.setTheme(Theme.Remax);
      return;
    }

    if (agent?.themeConfig === 'sulek') {
      this.setTheme(Theme.Sulek);
      return;
    }

    if (agent?.themeConfig === 'wohncloud') {
      this.setTheme(Theme.WohnCloud);
      return;
    }

    if (agent?.themeConfig === 'froeschl') {
      this.setTheme(Theme.Froeschl);
      return;
    }

    if (agent?.themeConfig === 'grasl') {
      this.setTheme(Theme.Grasl);
      return;
    }

    if (agent?.themeConfig === 'finova') {
      this.setTheme(Theme.Finova);
      return;
    }

    if (agent?.themeConfig === 'hagsteiner') {
      this.setTheme(Theme.Hagsteiner);
      return;
    }

    if (agent?.themeConfig === 'remax-collection') {
      this.setTheme(Theme.RemaxCollection);
      return;
    }

    if (agent?.themeConfig?.includes('remax-classic')) {
      this.setTheme(Theme.RemaxClassic);
      return;
    }

    if (agent?.themeConfig === 'edelweiss') {
      this.setTheme(Theme.Edelweiss);
      return;
    }

    if (agent?.themeConfig === 'eraImmo') {
      this.setTheme(Theme.EraImmo);
      return;
    }

    if (agent?.themeConfig === 'sonnberger') {
      this.setTheme(Theme.Sonnberger);
      return;
    }

    if (agent?.themeConfig === 'virimo') {
      this.setTheme(Theme.Virimo);
      return;
    }

    if (agent?.themeConfig === 'norisk') {
      this.setTheme(Theme.Norisk);
      return;
    }

    if (agent?.themeConfig === 'mayrImmo') {
      this.setTheme(Theme.MayrImmo);
      return;
    }

    if (agent?.themeConfig === 'prosch') {
      this.setTheme(Theme.Prosch);
      return;
    }

    if (agent?.themeConfig === 'ahImmobilien') {
      this.setTheme(Theme.AhImmobilien);
      return;
    }

    if (agent?.themeConfig === 'aaaaImmobilien') {
      this.setTheme(Theme.AaaaImmobilien);
      return;
    }

    if (agent?.themeConfig === 'altera') {
      this.setTheme(Theme.Altera);
      return;
    }

    if (agent?.themeConfig === 'qualis') {
      this.setTheme(Theme.Qualis);
      return;
    }

    if (agent?.themeConfig === '4immobilien') {
      this.setTheme(Theme.Immo4)
      return;
    }

    if (agent?.themeConfig === 'immobilien-melk') {
      this.setTheme(Theme.ImmobilienMelk)
      return;
    }
  }
}