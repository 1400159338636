import { gql } from 'apollo-angular';
import { PropertyOverview } from '@frontend/models';

export interface GraphQLResponsePropertyOverviewById {
  propertyOverview: PropertyOverview;
}

export const GET_PROPERTY_OVERVIEW_BY_ID = gql`
  query GetCustomerPortalById($id: String!) {
    propertyOverview(id: $id) {
      id
      agent {
        id
        email
        firstName
        lastName
        picture
        officeName
        legalEntity
        isRemax
        themeConfig
        phone
        googleReviewLink
        countrySettings
        taxBase
      }
      customerType
      businessCustomerId
      customersIds
      customerLanguage
      property {
        id
        title
        objectType
        orderType
        agent {
          id
          email
          firstName
          lastName
          phone
        }
        address {
          country
          city
          state
          zipCode
          streetName
          buildingNumber
          doorNumber
          stairway
        }
        roomsAndArea {
          livingArea {
            area
            areaCa
          }
          baseArea {
            area
            areaCa
          }
          usableArea {
            area
            areaCa
          }
        }
        price
        realtyPrice {
          purchase_price {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          rent {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          parking {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          garage {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          operating {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          heating {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          hot_water {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          cooling {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          maintenance_funds {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          elevator {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
          others {
            currency
            net
            gross
            vat_absolute
            vat_percentage
          }
        }
        creationDate
        images {
          id
          storageUrl
          type
          description
          order
        }
        displayImages
      }
      rentalOfferSettings {
        canSubmitRentalOffer
        askForPep
        mustHaveUploadsIndividual {
          title
          description
          mandatory
        }
        mustFillCustomerQuestionnaireIndividual
        mustFillCustomerQuestionnaireIndividualOnly
      }
      buyingOfferSettings {
        askForSufficientFinance
        askForPep
        askForMoneyLaundering
        canSubmitBuyingOffer
        mustHaveUploadsIndividual {
          title
          description
          mandatory
        }
      }
      viewingPassSettings {
        canSubmitViewingPass
      }
      individualSettings {
        hasFinanceCalculatorAccess
        hasDocumentAccess
        documents {
            title
            description
            storageUrl
        }
      }
      userData {
        rentalOffer {
          customersRelatedData {
            isSponsor
          }
          rentalStartDate
          handoverConditionDate
          handoverConditionNotes
          secondViewingDate
          timeLimitation
          tenantRestriction {
            noLimitation
            limitationNotes
          }
          documentPreviewUrl
          uploadedDocuments {
            id
            name
            url
            documentFrom
            relatedCustomerId
          }
        }
        viewingPass {
          documentPreviewUrl
          viewingDate
          offersAgreement
          agreedToNewsletter
          agreedToSurvey
          agreedToDataTransmissionToFranchise
          instructedCancellationConditions
          agentCanTakeActionBeforeCancellationPeriod
        }
        selfDeclaration {
          documentPreviewUrl
          declaration {
            title
            state
            signedDocumentUrl
            date
          }
        }
      }
      buyingOffer {
        isCreateAvailable
        buyingOfferId
        data {
          price
          handoverConditionDate
          handoverConditionNotes
          timeLimitation
          alternativeLimitation
          buyerRestriction {
            noLimitation
            limitationNotes
          }
          hasSufficientFinance
          documentPreviewUrl
          uploadedDocuments {
            id
            name
            url
            documentFrom
            relatedCustomerId
          }
        }
      }
      isPasswordProtected
    }
  }
`;